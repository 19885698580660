.details-info-container {
  width: 70%;
  margin: 0 auto;
  display: grid;
  grid: auto / auto auto;
  border: 1px solid #eee;
  padding: 30px 20px;
  border-radius: 20px;
}

.details-info-content {
  width: 100%;
}

.details-info-content label {
  display: block;
  margin: 14px 0;
}

.details-info-content span {
  padding: 5px 10px;
  border: 1px solid #eee;
  border-radius: 5px;
}

.bottom-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.r-pswd:valid + p {
  color: green !important;
}
