.document-create-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
}
.mydocument-images__container {
  position: relative;
}
.document-create-btn button {
  outline: none;
  border: none;
  padding: 4px 8px;
}
.modalHeader {
  margin-bottom: 20px;
}
.doc-label {
  margin-right: 5px;
}
.addModal {
  position: absolute;
  width: 60%;
  height: 500px;
  left: 0;
  right: 0;
  top: 30px;
  margin: 0 auto;
  text-align: center;
  background-color: rgb(245, 243, 243);
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  padding: 2rem;
  border-radius: 1rem;
  color: black;
  opacity: 0;
  transform: scale(0);
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 888;
}
.addModal.open,
.viewModal.open {
  transform: scale(1);
  visibility: visible;
  opacity: 1;
}
.mainContent-header {
  position: relative;
}
.backdrop-zone {
  margin-top: 1rem;
}
.backdrop-zone label {
  height: 100px !important;
}
.close-btn {
  text-align: right;
  cursor: pointer;
}
.gallery {
  display: grid;
  grid: 200px 200px / auto auto auto auto;
  gap: 1rem;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0 12px;
  position: relative;
  /* z-index: 500; */
}

.gallery .pictures {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
  position: relative;
  width: 200px;
  height: 200px;
}

.gallery .pictures img {
  object-fit: cover;
}

.gallery .pictures:hover {
  filter: opacity(0.7);
}

@media (max-width: 991px) {
  .gallery {
    grid: auto / auto auto;
  }
}
@media (max-width: 480px) {
  .gallery {
    grid: auto / auto;
  }
}

.viewModal {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0;
  transform: scale(0);
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 999;
}
.viewModal img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  margin: 0 auto;
}
.viewModal .close-btn {
  position: fixed;
  top: 1rem;
  right: 1rem;
}
.overlay-bg {
  position: absolute;
  /* top: 0; */
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d6efd;
  display: flex;
  align-items: flex-end;
  z-index: 100;
}
.overlay-bg .dflex {
  padding: 10px 20px;
}

.dflex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
