.form-wrapper {
  margin: 0 auto;
  min-height: calc(100vh - 20rem);
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container {
  margin: 0;
  width: 60%;
  background-color: white;
  box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.25);
  padding: 2rem;
  opacity: 0.95;
}

.login.form-container {
  width: min(30%, 50%);
}

.form-container form {
  display: grid;
  grid: auto / auto auto auto;
  gap: 1rem;
  margin-bottom: 2rem;
}

.login.form-container form {
  display: grid;
  grid: auto / auto;
}
.form-container form input {
  padding: 0.5rem;
  width: 100%;
}

.form-container .logo {
  width: 90px;
  height: 90px;
  margin: 0 auto;
}

.form-container .logo img {
  width: 100%;
}

.form-container .msg {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  color: black;
  margin-bottom: 2rem;
}

.form-container button {
  display: block;
  margin: 1rem auto;
}

.form-link {
  font-size: 1rem;
  color: black;
  text-align: center;
  margin-top: 1.5rem;
}

.formInput span {
  color: red;
  display: none;
}
input:invalid[focused="true"] {
  border: 1px solid red;
}
input:valid[focused="true"] {
  border: 1px solid green;
}
input:invalid[focused="true"] ~ span {
  display: block;
}

.errMsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 960px) {
  .form-container {
    width: 100%;
  }
  .login.form-container {
    width: 50%;
  }

  .form-container form {
    display: block;
  }
  /* .form-container form input {
    margin-bottom: 2rem;
  } */
}

@media only screen and (max-width: 768px) {
  .login.form-container {
    width: 90%;
  }
  .formInput span {
    position: relative;
    top: -1.3rem;
  }
}
