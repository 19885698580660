.table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th,
  .table td {
    padding: 8px !important;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 15px !important;
  }
  
  .table th {
    /* background-color: #f2f2f2; */
  }
  
  .table tbody tr:hover {
    /* background-color: #f5f5f5; */
  }
  .tablePrimaryBg {
    background-color: #C4DCFF;
    color: black;
  }
  
  /* tbody tr:nth-child(2){
    background-color: red;
  } */