.dashboard-content .dashboard-sideNav--header {
  position: relative;
}
.dashboard-content .dashboard-sideNav--header .logo {
  width: 90px;
  height: 90px;
  margin: 0 auto;
}
.dashboard-content .dashboard-sideNav--header .logo img {
  width: 100%;
}
.dashboard-sideNav .dashboard-sideNav--header .displayUserName {
  text-align: center;
}
.dashboard-content .dashboard-sideNav--header .displayUserName p {
  font-size: 16px;
  margin: 1rem 0 0.5rem;
}
.dashboard-content .dashboard-sideNav--header .displayUserName h3 {
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.sideNav-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.dashboard-content .dashboard-sideNav .sideNav-links {
  text-align: left;
}
.dashboard-content .dashboard-sideNav .sideNav-links .sideNav-link {
  margin: 2rem 0;
  padding: 1rem;
  cursor: pointer;
  border: 1px solid #fff;
  font-size: 14px;
}
.dashboard-content .dashboard-sideNav .sideNav-links .sideNav-link.active {
  background: #fff;
  color: black;
  border: none;
}

.dashboard-content .dashboard-sideNav .logout-btn button {
  width: 100%;
  padding: 1rem;
  border: none;
  background-color: white;
  color: black;
  font-size: 14px;
}

.dashboard-mainContent {
  padding: 30px 50px;
}
.dashboard-mainContent .mainContent-header h2 {
  color: black;
  font-weight: 600;
}
.dashboard-content .mainContent-EditProfile--btn {
  display: block;
  margin-left: auto;
  text-align: right;
}
.dashboard-mainContent .mainContent-profileInformation {
  margin-top: 20px;
}

.manageMarshallBody {
  font-size: 0.9rem;
}

.manageMarshallHead th {
  font-size: 1rem;
}

.manageMarshallTable {
  text-align: center;
}
.mainContent-EditProfile--btn label {
  font-size: 1rem;
  margin-right: 0.5rem;
  color: black;
}
.mainContent-EditProfile--btn input {
  padding: 0.5rem 1rem;
  vertical-align: middle;
  border: 1px solid #337ab7;
  border-radius: 5px;
  background: #fafaff;
}

.no-report {
  position: absolute;
  width: 80%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

@media (max-width: 420px) {
  .dashboard-content .mainContent-EditProfile--btn {
    text-align: left;
    margin-top: 1rem;
    position: relative;
  }
  .dashboard-mainContent {
    padding: 30px;
    overflow-x: hidden;
  }
  .report-header.mainContent-header .mainContent-EditProfile--btn {
    margin: 0;
  }
  .mainContent-EditProfile--btn label {
    display: none;
  }
  .mainContent-EditProfile--btn input {
    width: 100%;
  }
  .mainContent-EditProfile--btn table {
    /* overflow: scroll; */
    width: 100%;
  }
  .pagination-btn {
    text-align: center;
  }
  .pagination-btn button {
    margin-top: 1rem;
  }
  .sm-all-table {
    overflow-x: scroll;
  }
}
