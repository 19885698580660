/* .Sm-info-form {
  display: grid;
  grid: auto / auto auto auto;
} */

.Sm-info-form label {
  display: block;
  margin: 1rem 0;
}

.Sm-info-form input, .Sm-info-form select {
  padding: 1rem 1.5rem;
  min-height: 57px;
  min-width: 250px;
  width: 100%;
  margin-bottom: 1rem;
}

.editable {
  min-width: 250px;
  min-height: 57px;
  padding: 1rem 1.5rem;
  border: 1px solid black;
}

.profile-pic-container {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(244, 244, 244);
  position: relative;
}

.profile-pic-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}


.profile-pic-container  input {
  display: none;
}

.uploadImage-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
}

.uploadBtn {
  margin-left: 1rem;
}