.del-box__container {
  position: absolute;
  top: 50%;
  left: 25%;
  z-index: 999;
  background-color: white;
  color: black;
  padding: 20px;
  font-size: 0.8rem !important   ;
  border-radius: 15px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 8px 10px -6px rgba(0, 0, 0, 0.1);
}
.del-box__container h4 {
  margin-bottom: 1rem;
  font-size: 1.4rem !important;
}
.del-cta__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.del-cta__container button {
  padding: 10px 15px;
  border: none;
  outline: none;
  border-radius: 15px;
  cursor: pointer;
}
