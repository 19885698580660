/* =================================== */
/*	Basic Style 
/* =================================== */

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fff;
  font-family: "Open Sans", sans-serif;
  line-height: 21px;
  font-size: 13px;
  color: #6a737b;
  overflow-x: hidden;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
  
#preloader {
  background-color: #fff;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 1100;
}

#preloader > img {
  left: 47%;
  position: absolute;
  top: 48%;
}


figure,
p {
  margin: 0;
}

a {
  -webkit-transition: all 0.3s ease-in 0s;
  -moz-transition: all 0.3s ease-in 0s;
  -ms-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

iframe {
  border: 0;
}

a,
a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
  color: black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
}

.woot-widget-bubble {
  bottom: 65px !important;
}
