/* 
background: red;
    border: 0;
    color: white;
    padding: 1rem 1.5rem; */
.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

table {
  width: 100%;
  text-align: left;
  margin-top: 10px;
  font-size: 1rem;
  font-weight: 300;
  color: black;
}

table thead {
  background: #337ab7;
  color: white;
  font-weight: 600;
}
table thead th {
  padding: 1rem;
}

table thead th:first-of-type,
table thead th:last-of-type {
  text-align: center;
}
table tbody tr td:first-of-type {
  font-weight: 500;
}
table tbody tr td:first-of-type,
table tbody tr td:last-of-type {
  text-align: center;
}

table tbody tr td:nth-child(2) {
  font-size: 1rem;
  padding: 1rem;
}

table tbody tr:nth-child(even) {
  background: #fafaff;
}

.action-btns svg {
  margin: 0 0.3rem;
  cursor: pointer;
}

.pagination-btn {
  margin-top: 10px;
}

.pagination-btn button {
  padding: 1rem 1.5rem;
  background-color: #337ab7;
  color: white;
  border: 0;
  cursor: pointer;
}

.pagination-btn button:first-of-type {
  margin-right: 1rem;
}

.Modal {
  position: fixed;
  height: auto;
  inset: 0;
  background: white;
  overflow: auto;
  outline: none;
  margin: auto;
  padding: 2rem;
}

.Overlay {
  position: -webkit-fixed;
  position: -moz-fixed;
  position: -o-fixed;
  position: -ms-fixed;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000bd;
  z-index: 100;
}
.addReportForm-Container {
  color: black;
}
/* .addReportForm-Container form {
  display: grid;
  grid: auto / auto auto;
  gap: 1rem;
} */
.addReportForm-Container label {
  display: block;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 1rem 0;
}

.addReportForm-Container input,
.addReportForm-Container select,
.addReportForm-Container textarea {
  width: 100%;
  padding: 1rem 0.5rem;
}

.addReportForm-Container select {
  width: 20%;
}

.addReportForm-Container textarea {
  resize: none;
}

.addReportForm-Container button {
  display: block;
  margin-left: auto;
  padding: 0.8rem 1.5rem;
  background-color: #337ab7;
  color: white;
  border: 0;
  cursor: pointer;
  margin-top: 2rem;
}

.upload-file-label {
  width: 100%;
}

.upload-file-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgb(118, 118, 118);
  height: 57px;
}

.upload-file-input-container input {
  padding: .5rem;
}

.upload-file-input-container button {
  margin: 0;
  margin-right: 5px;
}