.bg {
  background-color: #337ab7;
}

.no-suggestions {
  background: #fff;
  color: red;
  padding: 0.5rem;
}
.suggestions {
  background-color: #fff;
  position: relative;
  top: 10px;
  /* border: 1px solid #999; */
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  /* max-height: 143px; */
  /* overflow-y: scroll; */
  padding-left: 0;
  width: 100%;
  z-index: 1000;
}
.suggestions li {
  padding: 0.5rem;
  font-size: 14px;
}
/* .suggestion-active, */
.suggestions li:hover {
  background-color: #8dbdf0;
  color: #111;
  cursor: pointer;
  font-weight: 400;
}
