.lookup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 20rem);
}

.search-container,
.searchResults {
  position: relative;
  width: max(310px, 50%);
  background-color: white;
  box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.25);
  padding: 2rem;
  opacity: 0.95;
}
.search-container {
  margin-bottom: 2rem;
}

.searchField {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid grey;
}

.searchField input {
  padding: 1rem 0;
  width: 100%;
  border: none;
  outline: none;
  margin-left: 1rem;
  font-size: 1.2rem;
}

.search-error {
  background: #f51717bf;
  color: white;
  text-align: center;
  padding: 0.5rem 0;
  margin-bottom: 2rem;
}

.backHomBtn {
  margin-top: 2rem;
  margin-left: 2rem;
}

.result-banner {
  width: 100%;
  height: 200px;
  background: #0d6efd;
}

.result-container {
  width: 100%;
  position: relative;
  top: -6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
}

.edit.result-container .r-text {
  width: 100%;
}

.result-content {
  width: 80%;
  /* display: flex;
  justify-content: center; */
}

.result-image {
  background: #fdfdfd;
  border-radius: 1rem;
  box-shadow: 0px 6.47807px 7.40351px rgba(194, 192, 192, 0.25);
  padding: 1rem;
  max-height: 15rem;
  position: relative;
  display: flex;
  justify-content: center;
}

.result-text {
  background: #fdfdfd;
  border-radius: 1rem;
  box-shadow: 0px 6.47807px 7.40351px rgba(194, 192, 192, 0.25);
  padding: 1rem;
  width: 100%;
  margin-left: 2rem;
  /* display: grid;
  grid: auto / auto auto;
  gap: 1rem; */
}

.result-text h5 {
  color: #0d6efd;
}
.r-text {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: black;
  border: 1px solid;
  background: #fafafa;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.RModal {
  position: fixed;
  height: auto;
  inset: 0%;
  background: white;
  overflow: auto;
  outline: none;
  margin: auto;
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  .result-content {
    width: 100%;
  }
  .search-container {
    width: 90%;
  }
  .result-container {
    padding: 1rem;
  }
  .result-text {
    margin-left: 0;
    margin-top: 2rem;
  }
}

.overlay-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .8;
  border-radius: 100%;
  z-index: -1;
}